import React, { useState, useEffect } from "react";
import LogoLight from "../../assets/images/logo-light.png";
import LogoDark from "../../assets/images/logo-dark.png";
import { useLightMode } from "../../contexts/MainContext";

const Logo = ({ className, height }) => {
  const { isLightMode } = useLightMode();

  return (
    <img
      className={className}
      src={!isLightMode ? LogoLight : LogoDark}
      alt=""
      height={height ? height : 'auto'}
    />
  );
};

export default Logo;
