import { Routes, Route } from "react-router-dom";
import Movies from "./components/movies";
import Home from "./components/homePage";
import LeaderBoard from "./components/leaderboard";
import YesOrNo from "./components/yesOrNo";
import Login from "./components/login";
import Register from "./components/register";
import Profile from "./components/profile";
import Playlists from "./components/playlists";
import EditProfile from "./components/profile/editProfile";
import Audience from "./components/audience";
import SingleAudience from "./components/audience/detail";
import ProfileLeaderboard from "./components/profile/leaderboard";
import ProtectedRoute from "./ProtectedRoute";
import NotFound from "./components/NotFound";

const routes = ({ open, setOpen, filterOpen , setFilterOpen}) => {
    return (
      <Routes>
        <Route
          path="/"
          element={
            // <ProtectedRoute>
            <Home
              open={open}
              setOpen={setOpen}
              filterOpen={filterOpen}
              setFilterOpen={setFilterOpen}
            />
            // </ProtectedRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />

        <Route
          path="/profile/leaderboard"
          element={
            <ProtectedRoute>
              <ProfileLeaderboard />
            </ProtectedRoute>
          }
        />

        <Route
          path="/profile/edit"
          element={
            <ProtectedRoute>
              <EditProfile />
            </ProtectedRoute>
          }
        />

        <Route
          path="/profile/playlists"
          element={
            <ProtectedRoute>
              <Playlists />
            </ProtectedRoute>
          }
        />

        <Route
          path="/audience"
          element={
            <ProtectedRoute>
              <Audience />
            </ProtectedRoute>
          }
        />

        <Route
          path="/audience/:username/:id"
          element={
            <ProtectedRoute>
              <SingleAudience />
            </ProtectedRoute>
          }
        />

        <Route
          path="/movies"
          element={
            <Movies
              open={open}
              setOpen={setOpen}
              filterOpen={filterOpen}
              setFilterOpen={setFilterOpen}
            />
          }
        />

        <Route path="/leaderboard" element={<LeaderBoard />} />

        <Route
          path="/tv-shows"
          element={
            <Movies
              open={open}
              setOpen={setOpen}
              filterOpen={filterOpen}
              setFilterOpen={setFilterOpen}
            />
          }
        />

        <Route
          path="/yes-or-no"
          element={<YesOrNo setFilterOpen={setFilterOpen} />}
        />

        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    );
};

export default routes;
