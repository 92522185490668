import * as React from "react";
import Movie from "./movieCard";
import { useYesNoMovies, useUpdateMovie } from "../../hooks/apis/movies";
import { useLocation } from "react-router-dom";
import SmallMovieCard from "./movieSmallCard";
import { getUser } from "../../hooks/apis/Auth";


export default function Index({ open, filterOpen, setFilterOpen }) {
  const user = getUser();
  const [currentMovie, setCurrentMovie] = React.useState(null);
  const [yesMovies, setYesMovies] = React.useState([]);
  const [noMovies, setNoMovies] = React.useState([]);
  const [currentMovieIndex, setCurrentMovieIndex] = React.useState(0);

  const location = useLocation();
  const path = location?.pathname;
  const type = path === "/tv-shows" ? "tv-show" : "movie";
  const { isFetching, data, refetch } = useYesNoMovies({ type: type });
  const [moviesList, setMoviesList] = React.useState([]);
  const { mutate: updateMovie } = useUpdateMovie();

  // Handle data fetching and initialization of movie list
  React.useEffect(() => {
    if (data?.length > 0) {
      setMoviesList(data);
      setCurrentMovie(data[0]); // Set the first movie
      setCurrentMovieIndex(0); // Reset index
    }
  }, [data]);

  const get_next_movie = () => {
    if (moviesList.length > 0) {
      // Remove the current movie from the list
      const updatedMoviesList = moviesList.filter(
        (_, index) => index !== currentMovieIndex
      );

      // Update the state
      setMoviesList(updatedMoviesList);

      if (updatedMoviesList.length > 0) {
        // If there are still movies left, set the next one as the current movie
        setCurrentMovie(updatedMoviesList[0]); // Get the next movie
        setCurrentMovieIndex(0); // Reset the index
      } else {
        // If the list is empty, refetch data and reset current movie
        setCurrentMovie(null);
        refetch(); // Fetch a new batch of movies
      }
    }
  };

  const addYesMovie = (movie) => {
    console.log(movie);
    
    setYesMovies((prevMovies) => {
      const movieExists = prevMovies.some((m) => m.movie_id === movie.movie_id);
      if (!movieExists) {
        return [...prevMovies, movie];
      }
      return prevMovies;
    });
    if (user){
      updateMovie({
        movie_id: movie?.movie_id,
        type: movie?.type,
        watching_status: "1",
        in_leaderboard:true,
      });
    }
    get_next_movie(); // Move to the next movie
  };

  const addNoMovie = (movie) => {
    setNoMovies((prevMovies) => {
      const movieExists = prevMovies.some((m) => m.movie_id === movie.movie_id);
      if (!movieExists) {
        return [...prevMovies, movie];
      }
      return prevMovies;
    });
    get_next_movie(); // Move to the next movie
  };

  return (
    <>
      <section className="home-section">
        <div className="ad" style={{ width: "15%" }}>
          <div
            className="text-primary d-flex justify-content-center align-items-center  text-dark"
            style={{ width: "90%", height: "95%" }}
          >
            <div
              className="mt-4 advertise-placeholder"
              style={{ width: "160px", height: "600px" }}
            >
              <span>Ad Area</span>
              <span>160 X 600</span>
            </div>
          </div>
        </div>

        <div className="movies-row">
          <div
            className="yes-no-card py-4 px-3"
            style={{ width: "30%", overflowY: "scroll" }}
          >
            {noMovies?.map((movie) => {
              return <SmallMovieCard key={movie.id} movie={movie} />;
            })}
          </div>

          <Movie
            addYesMovie={addYesMovie}
            addNoMovie={addNoMovie}
            movie={currentMovie}
            isLoading={false}
            isFetching={false}
            get_next_movie={get_next_movie}
            setFilterOpen={setFilterOpen}
          />

          <div
            className="yes-no-card py-4 px-3"
            style={{ width: "30%", overflowY: "scroll" }}
          >
            {yesMovies?.map((movie) => {
              return <SmallMovieCard key={movie.id} movie={movie} />;
            })}
          </div>
        </div>

        <div className="ad" style={{ width: "15%" }}>
          <div
            className="text-primary d-flex justify-content-center align-items-center  text-dark"
            style={{ width: "90%", height: "95%" }}
          >
            <div
              className="mt-4 advertise-placeholder"
              style={{ width: "160px", height: "600px" }}
            >
              <span>Ad Area {moviesList?.length}</span>
              <span>160 X 600</span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
