import axios from "axios";
import {getLocalstorageWithExpiration} from "./../../utils";
import { toast } from "react-toastify";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true, // Enables cookies for session persistence
});

api.interceptors.response.use(
  (response) => {
    if (response?.data?.responseMessage && response.config.method !== "get") {
      toast.success(response.data.responseMessage);
    }
    return response;
  },
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }
      const errorMessage = error.response.data?.responseMessage[0] || "An error occurred";
      toast.error(errorMessage);
    } else if (error.request) {
      toast.error("Something Went Wrong");
    } else {
      console.error("Error message:", error.message);
    }
    return Promise.reject(error);
  }
);


api.interceptors.request.use((config) => {
  const token = getLocalstorageWithExpiration("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});




export default api;
