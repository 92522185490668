import * as React from "react";
import { UilMultiply } from "@iconscout/react-unicons";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import MovieDetailsBody from './movieDetailsBody'


const movieModal = ({ handleClose, isFetchingMovie, movieData , open}) => {
      const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "#fff",
        border: "0px",
        borderRadius: "10px",
        boxShadow: 24,
        pt: 4,
        px: 4,
        pb: 4,
        width: "35%",
        height: "80vh",
        overflow: "auto",
      };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={style}
        className="leaderboard-movie-bg"
        style={{
          backgroundImage: `url(${!isFetchingMovie && movieData?.data?.image})`,
        }}
      >
        {isFetchingMovie ? (
          <div className="movie-details d-flex justify-content-center align-items-center">
            <CircularProgress color="inherit" />
          </div>
        ) : (
          <>
            <div className="movie-details">
              {/* header */}
              <div className="movie-detail-header">
                <UilMultiply class="text-color" onClick={handleClose} />
              </div>
              <MovieDetailsBody movieData={movieData} />
            </div>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default movieModal;
