import React from 'react'

export default function movieSmallCard(movie) {
  return (
    <>
      <div className="leader-cell d-flex align-items-center mb-2">
        <img className='m-1' src={movie?.movie?.image} alt="" style={{ height: "60px" }} />
        <p className="m-0 px-2">{movie?.movie?.title}</p>
      </div>
    </>
  );
}
