import React from 'react'
import placeholderPoster from "../../assets/images/MoviePosterPlaceholder.png";
import imdbIcon from "../../assets/images/imdb.png";

export default function movieDetails({ movieData }) {
  return (
    <>
      {/* body */}
      <div className="movie-detail-body">
        <div className="d-flex justify-content-between mb-3">
          <div>
            <h1 class="text-color">{movieData?.data?.title}</h1>
            <p class="text-color">
              {movieData?.data?.year} | R | {movieData?.data?.runtimeStr} |{" "}
              {movieData?.data?.imDbRating.toFixed(1)}
              <i className="uil uil-star" />
              <img src={imdbIcon} height="25px" alt="" />
            </p>
          </div>
          <img
            src={movieData?.data?.image || placeholderPoster}
            className="thumb px-2"
            alt={movieData?.data?.title || "No Title"}
          />
        </div>

        {movieData?.data?.directors.length !== 0 && (
          <p className="detail-item directorList text-color">
            <span>Director : </span>
            {movieData?.data?.directors.length < 6
              ? movieData?.data?.directors.map((item) => item.name).join(", ")
              : movieData?.data?.directors
                  .slice(0, 6)
                  .map((item) => item.name)
                  .join(", ")}
          </p>
        )}

        {movieData?.data?.writers.length !== 0 && (
          <p className="detail-item writerList text-color">
            <span>Writers : </span>
            {movieData?.data?.writers.length < 6
              ? movieData?.data?.writers.map((item) => item.name).join(", ")
              : movieData?.data?.writers
                  .slice(0, 6)
                  .map((item) => item.name)
                  .join(", ")}
          </p>
        )}

        {movieData?.data?.stars.length !== 0 && (
          <p className="detail-item starList text-color">
            <span>Stars : </span>
            {movieData?.data?.stars.length < 6
              ? movieData?.data?.stars.map((item) => item.name).join(", ")
              : movieData?.data?.stars
                  .slice(0, 6)
                  .map((item) => item.name)
                  .join(", ")}
          </p>
        )}
        <p className="detail-item mt-4 text-color">
          <span>Storyline :</span> {movieData?.data?.description}
        </p>

        {movieData?.data?.watch_providers?.rent?.map((item) => {
          return <>{item}</>;
        })}
      </div>

    </>
  );
}
